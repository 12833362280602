
export const deviceIdKeyString = 'deviceId';
export const subscriptionsKeyString = 'subscriptions';
export const webLinksKeyString = 'webLinks';
export const linksShowingKeyString = 'linksShowing';
export const requirePhotoApprovalKeyString = 'requirePhotoApproval';
export const nextChosenSubscriptionUrlKeyString = 'nextChosenSubscriptionUrl';
export const nextChosenSubscriptionPhotoKeyString = 'nextChosenSubscriptionPhoto';
export const nextChosenSubscriptionPhoto64KeyString = 'nextChosenSubscriptionPhoto64';
export const amplifyAuthState = 'amplify-authenticator-authState';
export const bookmarkFolderKeyString = 'latencyBookmarkFolderId';
export const appNameForBookmarks = 'Latency';
import * as constants from '../Constants';
import { Auth } from 'aws-amplify';

function getAndParseObjectFromLocalStorage(keyString) {
    var storedString = localStorage[keyString];
    var storedData = null;
    if(storedString) {
        storedData = JSON.parse(storedString);
    }
    console.log("Got new data from storage in NewTabLoader");
    return storedData;
}

function getDaysFromNowEpoch(days) {
    var currentTime = Math.floor(Date.now() / 1000);
    var day = 86400; //seconds in day.
    var expiration = (currentTime + (day * days));
    return expiration;
}

function getStorageKeyString(deviceId, keyString) {
    if(deviceId) {
        return "preview-" + keyString;
    }
    return keyString;
}

function getStorageKeyStringForUser(deviceId, userId, keyString) {
    var storageKey = "";
    if(deviceId) {
        storageKey = storageKey + "preview-";
    }
    if(userId) {
        storageKey = storageKey + "user-" + userId;
    }
    return storageKey + keyString;
}

function clearUserStorageForDevice() {
    localStorage.removeItem(constants.subscriptionsKeyString);
    localStorage.removeItem(constants.webLinksKeyString);
    localStorage.removeItem(constants.linksShowingKeyString);
    localStorage.removeItem(constants.nextChosenSubscriptionUrlKeyString);
    localStorage.removeItem(constants.nextChosenSubscriptionPhotoKeyString);
    localStorage.removeItem(constants.nextChosenSubscriptionPhoto64KeyString);
    //TODO: you screwed this up. This can't be a local setting that goes away on sign out, but if it doesn't it can be overridden per user.
    //export const requirePhotoApprovalKeyString = 'requirePhotoApproval';
    
}
function clearUserStorageAndSignOut() {
    clearUserStorageForDevice();

    Auth.signOut();
    
}

function getCloudfrontUrlFromKey(imgKey) {
    if(imgKey) {
        var cdnUrl = "https://"+process.env.REACT_APP_CLOUDFRONT_ID+".cloudfront.net/"+imgKey;
        return cdnUrl;
    }
    return null;
}
/*
async function getCachedAndRefresh(subscriptionData, propsDeviceId, localDeviceId) {
    if(localDeviceId) {
        setServerRefreshFinished(true);
        delay((subscriptionData) ? 5000 : 0).then(() => {
            const fetchData = async () => {
                var serverSubscriptions = await getAndSaveSubscriptions(propsDeviceId, localDeviceId);
                if(serverSubscriptions) {
                    onSubscriptionPhotoStateChanged(null);
                }
            };
            fetchData();
        });
        if(subscriptionData) {
            onSubscriptionPhotoStateChanged(null);
        }
    }
}
//TODO: This ignores props.id which I think is correct, but you should remove that code if it's not useful.
if(!serverRefreshFinished) {
    setLinksShowing(getBoolFromLocalStorageWithDefault(props.deviceId, linksShowingKeyString, true));
    setSubscriptionsShowing(getBoolFromLocalStorageWithDefault(props.deviceId, subscriptionsShowingKeyString, false));
    
    if(props.deviceId) {
        clearPreviewStorage(props.deviceId);
    }
    var device = (props.deviceId) ? props.deviceId : localStorage[getStorageKeyString(props.deviceId, deviceIdKeyString)];
    var subscriptionData = getAndParseObjectFromLocalStorage(getStorageKeyString(props.deviceId, subscriptionsKeyString));
    getSubscriptionData(subscriptionData, props.deviceId, device);
}
}*/



export { getAndParseObjectFromLocalStorage, getDaysFromNowEpoch, getStorageKeyString, getStorageKeyStringForUser, clearUserStorageAndSignOut, clearUserStorageForDevice, getCloudfrontUrlFromKey };
//import 'bootstrap/dist/css/bootstrap.min.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import './minIndex.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as constants from './Constants';
import { getCloudfrontUrlFromKey } from './components/FunctionHelpers'
/*global chrome*/

function getAndParseObjectFromLocalStorage(keyString) {
    var storedString = localStorage[keyString];
    var storedData = null;
    if(storedString) {
        storedData = JSON.parse(storedString);
    }
    console.log("Got new data from storage in NewTabLoader");
    return storedData;
}

function addChildrenToList(documentFragment, bookmarkTreeNode) {
    for (var j = 0; j < bookmarkTreeNode.children.length; j++) {
        var bookmarkNode = bookmarkTreeNode.children[j];
        if(bookmarkNode.children) {
            addChildrenToList(documentFragment, bookmarkNode);
        }
        else {
            var bookmarkDiv = document.createElement("div");
            bookmarkDiv.style.display = "inline-block";
            bookmarkDiv.style.marginRight = "6px";
            bookmarkDiv.style.marginLeft = "6px";
            bookmarkDiv.style.lineHeight = "1rem";
            bookmarkDiv.style.verticalAlign = "text-bottom";
            
            var bookmarkLink = document.createElement("a"); 
            var bookmarkLinkText = document.createTextNode(bookmarkNode.title); 
            var bookmarkImg = document.createElement("img");
            bookmarkImg.style.marginTop = "-4px";
            bookmarkImg.style.marginRight = "3px";
            bookmarkImg.style.height = "16px";
            bookmarkImg.style.width = "16px";
            bookmarkImg.src = "chrome://favicon/".concat(bookmarkNode.url);
            bookmarkLink.appendChild(bookmarkImg);
            bookmarkLink.appendChild(bookmarkLinkText);  
            bookmarkLink.title = bookmarkNode.title;
            bookmarkLink.href = bookmarkNode.url;  
            bookmarkDiv.appendChild(bookmarkLink);

            var closeButtonSpacerDiv = document.createElement("div");
            closeButtonSpacerDiv.style.display = "inline-block";
            closeButtonSpacerDiv.style.width = "24px";
            closeButtonSpacerDiv.style.height = "20px";
            closeButtonSpacerDiv.style.verticalAlign = "text-bottom";
            bookmarkDiv.appendChild(closeButtonSpacerDiv);
            
            documentFragment.appendChild(bookmarkDiv);
        }
    }
}

function syncBookmarks(bookmarkId) {
    chrome.bookmarks.getSubTree(
        bookmarkId,
        function(bookmarkTreeNodes) {

            var quickNavBar = document.getElementById("quickNavBar"); 
            quickNavBar.className = "animatedFastFadeIn"

            var quickLeftNavBar = document.createElement("div"); 
            quickLeftNavBar.id = 'quickLeftNavBar';
            quickLeftNavBar.style.flex = "1 0 100px"
            quickLeftNavBar.style.padding = "0px 15px 0px 15px";

            var quickBookmarkDiv = document.createElement("div"); 
            quickBookmarkDiv.id = 'quickBookmarkDiv';
            quickBookmarkDiv.style.flex = "0 1 auto";
            quickBookmarkDiv.style.width = "auto";
            quickBookmarkDiv.style.maxWidth = "100%";
            quickBookmarkDiv.style.padding = "0px 15px 0px 15px";
            quickBookmarkDiv.style.textAlign = "center";

            var quickRightNavBar = document.createElement("div"); 
            quickRightNavBar.id = 'quickRightNavBar';
            quickRightNavBar.style.flex = "1 0 160px";
            quickRightNavBar.style.display = "inline-block";
            quickRightNavBar.style.lineHeight = "26.5px";
            quickRightNavBar.style.padding = "0px 3px 0px 15px";
            //var quickShowLinksButton = document.createElement("div"); 
            //quickShowLinksButton.style.width = "100px";
            //quickShowLinksButton.style.height = "24.5px";
            //quickShowLinksButton.style.display = "inline-block";
            var quickSubscriptionsButton = document.createElement("div"); 
            quickSubscriptionsButton.style.width = "130px";
            quickSubscriptionsButton.style.height = "23.5px";
            quickSubscriptionsButton.style.display = "inline-block";
            quickSubscriptionsButton.style.padding = "0px";
            quickSubscriptionsButton.style.verticalAlign = "text-bottom";
            /*var quickSubscriptionsButtonIcon = document.createElement("div"); 
            quickSubscriptionsButtonIcon.style.width = "20px";
            quickSubscriptionsButtonIcon.style.height = "20px";
            quickSubscriptionsButtonIcon.style.display = "inline-block";
            var quickSubscriptionsButtonText = document.createElement("div"); 
            quickSubscriptionsButtonText.style.width = "110px";
            quickSubscriptionsButtonText.style.height = "39px";
            quickSubscriptionsButtonText.style.display = "inline-block";
            quickSubscriptionsButton.appendChild(quickSubscriptionsButtonIcon);
            quickSubscriptionsButton.appendChild(quickSubscriptionsButtonText);*/

            var quickMeDropdownButton = document.createElement("div"); 
            quickMeDropdownButton.style.width = "72px";
            quickMeDropdownButton.style.height = "23.5px";
            quickMeDropdownButton.style.display = "inline-block";
            quickMeDropdownButton.style.padding = "0px";
            quickMeDropdownButton.style.verticalAlign = "text-bottom";

            //quickRightNavBar.appendChild(quickShowLinksButton); //TODO: See if you really care to know whether this will display.
            quickRightNavBar.appendChild(quickSubscriptionsButton);
            quickRightNavBar.appendChild(quickMeDropdownButton);

            var documentFragment = document.createDocumentFragment();
            for (var i = 0; i < bookmarkTreeNodes.length; i++) {
                addChildrenToList(documentFragment, bookmarkTreeNodes[i]);
            }
            quickBookmarkDiv.appendChild(documentFragment);

            var addButtonSpacerDiv = document.createElement("div");
            addButtonSpacerDiv.style.display = "inline-block";
            addButtonSpacerDiv.style.width = "110px";
            addButtonSpacerDiv.style.height = "24.5px";
            addButtonSpacerDiv.style.verticalAlign = "text-bottom";
            quickBookmarkDiv.appendChild(addButtonSpacerDiv);
            
            quickNavBar.appendChild(quickLeftNavBar);
            quickNavBar.appendChild(quickBookmarkDiv);
            quickNavBar.appendChild(quickRightNavBar);
            addImage();
        }
    );
};
function syncBookmarksFromCreatedNode(bookmarkNode) {
    syncBookmarks(bookmarkNode.id);
};
function findBookmarkFolder(bookmarkNodes) {
    var folderName = constants.appNameForBookmarks + " tab bookmarks";
    for (var i = 0; i < bookmarkNodes.length; i++) {
        for (var j = 0; j < bookmarkNodes[i].children.length; j++) {
            var bookmarkNode = bookmarkNodes[i].children[j];
            if (bookmarkNode.title) {
                if (bookmarkNode.title.localeCompare(folderName) === 0) {
                    syncBookmarks(bookmarkNode.id);
                    return;
                }
            }
        }
    }

    chrome.bookmarks.create({
        'title': folderName},
        syncBookmarksFromCreatedNode);
};
function loadBookmarks() {
    if(chrome && chrome.bookmarks) {
        var bookmarkFolderId = localStorage[constants.bookmarkFolderKeyString];
        if(bookmarkFolderId) {
            chrome.bookmarks.get(
                bookmarkFolderId,
                findBookmarkFolder
            );
        }
        else {
            chrome.bookmarks.getSubTree("2", //2 is the index value of the Other Bookmarks.
                findBookmarkFolder
            );
        }
    }
    else {
        addImage();
    }
};

function addImage () {
    /*var quickImgDiv = document.createElement("div"); 
    quickImgDiv.id = 'quickImgDiv';
    quickImgDiv.style.backgroundSize = "cover";
    quickImgDiv.style.backgroundRepeat = "no-repeat";
    quickImgDiv.style.backgroundPosition = "center";
    quickImgDiv.style.backgroundPosition = "center";
    quickImgDiv.style.width = "100%";
    quickImgDiv.style.flexGrow = "1";
    quickImgDiv.className = "animatedFadeIn";*/
    var nextChosenSubscription = getAndParseObjectFromLocalStorage(constants.nextChosenSubscriptionPhotoKeyString);
    var nextChosenSubscriptionPhoto64 = localStorage["nextChosenSubscriptionPhoto64"];
    
    var quickImgElement = document.createElement("img");
    quickImgElement.className = "animatedFadeIn"
    quickImgElement.crossOrigin = "anonymous";
    //var nextChosenSubscription = null;
    if(nextChosenSubscriptionPhoto64) {
        //var urlString = "url(" + nextChosenSubscriptionPhoto64 + ")";
        //quickImgDiv.style.backgroundImage = urlString;

        quickImgElement.src = nextChosenSubscriptionPhoto64;
    }
    else if(nextChosenSubscription.isVideo)
    {
        // next one is a video but we didn't successfully save the preview image, just use the themed color
    }
    else if(nextChosenSubscription) {
        //var urlString = "url(" + getCloudfrontUrlFromKey(nextChosenSubscription.fullsize.key) + ")";
        //quickImgDiv.style.backgroundImage = urlString;

        quickImgElement.src = getCloudfrontUrlFromKey(nextChosenSubscription.fullsize.key);
    }

    quickImgElement.style.objectFit = "cover";
    quickImgElement.style.height = "100%";
    quickImgElement.style.width = "100%";
    quickImgElement.style.flexGrow = "1";
    quickImgElement.style.overflow = "hidden";
    
    if(nextChosenSubscription) {
        var horizontalAlignPercent = (!nextChosenSubscription.displaySettings || nextChosenSubscription.displaySettings.horizontalAlignPercent === null) ? "50" : nextChosenSubscription.displaySettings.horizontalAlignPercent;
        var verticalAlignPercent = (!nextChosenSubscription.displaySettings || nextChosenSubscription.displaySettings.verticalAlignPercent === null) ? "50" : nextChosenSubscription.displaySettings.verticalAlignPercent;
        var horizontalAlignPercentString = (horizontalAlignPercent + "%");
        var verticalAlignPercentString = (verticalAlignPercent + "%");
        //quickImgDiv.style.backgroundPositionX = horizontalAlignPercentString;
        //quickImgDiv.style.backgroundPositionY = verticalAlignPercentString;

        quickImgElement.style.objectPosition = horizontalAlignPercentString + " " + verticalAlignPercentString;
    }
    var quickFullHeightDiv = document.getElementById("minFullHeight"); 
    //quickFullHeightDiv.appendChild(quickImgDiv);
    quickFullHeightDiv.appendChild(quickImgElement);

    var quickDiv = document.getElementById("minRoot"); 
    var quickCaptionDiv = document.createElement("div"); 
    quickCaptionDiv.style.minHeight = "40px";
    quickDiv.appendChild(quickCaptionDiv);

}

function addElement () { 
    // create a new div element 
    
    if(chrome && chrome.webRequest) {
        console.log("adding webrequest listener");
        chrome.webRequest.onBeforeRequest.addListener(
            function(details) {
            if(details.url.startsWith("chrome-extension://" + chrome.runtime.id + "/u/") || 
                details.url.startsWith("chrome-extension://" + chrome.runtime.id + "/d/") ||
                details.url === ("chrome-extension://" + chrome.runtime.id + "/me") || 
                details.url === ("chrome-extension://" + chrome.runtime.id + "/home") || 
                details.url === ("chrome-extension://" + chrome.runtime.id + "/settings") || 
                details.url === ("chrome-extension://" + chrome.runtime.id + "/newtab")) {
                    return {redirectUrl:  "chrome-extension://" + chrome.runtime.id + "/index.html"};
            }
            }, {
            urls: ["chrome-extension://" + chrome.runtime.id + "/*"]
            }, [
            "blocking"
            ]
        );
    }

    var quickDiv = document.createElement("div"); 
    quickDiv.id = 'minRoot';
    quickDiv.style.position = "absolute";
    quickDiv.style.height = "100%";
    quickDiv.style.width = "100%";
    quickDiv.style.zIndex = "-1000";
    //quickDiv.style.background = "#202124";
    var quickFullHeightDiv = document.createElement("div"); 
    quickFullHeightDiv.id = 'minFullHeight';
    quickFullHeightDiv.style.display = "flex";
    quickFullHeightDiv.style.flexDirection = "column";
    quickFullHeightDiv.style.height = "100%";
    quickDiv.appendChild(quickFullHeightDiv);
    
    var quickNavBar = document.createElement("div"); 
    quickNavBar.id = 'quickNavBar';
    quickNavBar.style.display = "flex";
    quickNavBar.style.width = "100%";
    //quickNavBar.style.minHeight = "26.5px";
    quickFullHeightDiv.appendChild(quickNavBar);

  
    // add the newly created element and its content into the DOM 
    var currentRootDiv = document.getElementById("root"); 
    document.body.insertBefore(quickDiv, currentRootDiv);

    function delay(timeout) {
        return new Promise(res => {
          setTimeout(res, timeout);
        });
    }
    loadBookmarks();

    delay(200).then(() => {
        if (navigator.onLine) { 
            console.log("got listener");
            import("./reactIndex").then(react => {
            });
        }
    });
    delay(1500).then(() => {
        if (navigator.onLine) { 
            document.body.style.background = "whitesmoke";
            var minRoot = document.getElementById("minRoot");
            if(minRoot) {
                //minRoot.parentNode.removeChild(minRoot);
            }
        }
    });
}

if (process.env.REACT_APP_IS_EXTENSION === 'true') {
    addElement();
}
else {
    import("./reactIndex").then(react => {
    });
}

//ReactDOM.render(<App />, document.getElementById('root'));
//ReactDOM.render(<MinApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
